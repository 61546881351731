import { useSearchParams } from "react-router-dom"
import style from "../css/About.module.css"
import { useEffect, useRef, useState } from "react";
import mainLogo from "../image/component/logo_column-white.svg"
import mainLogoE from "../image/component/logoE_column-white.svg"
import noDataImg from "../image/about/nodata.png"
import outlineImgTop from "../image/about/outline_content_top.png"
import outlineImgBottom from "../image/about/outline_content_bottom.svg"
import outlineImgTopEn from "../image/about/outline_content_top_en.png"
import outlineImgBottomEn from "../image/about/outline_content_bottom_en.svg"
import axios from "axios";
import SEOMetaTag from "../Components/SEOMetaTag";
import Portal from '../Components/Portal';
import AdminModal from '../AdminComponents/AdminModal';
import partner_logo1 from "../image/about/partners/partner2.png"
import partner_logo2 from "../image/about/partners/partner1.png"
import { NavLink } from 'react-router-dom';

export default function About({isEn, admin, setAdmin, showAdmin, setShowAdmin, adminField, setAdminField, render, setRender}) {
    const [searchParams, setSearchParams] = useSearchParams();
    const [column, setColumn] = useState(searchParams.get("column"));
    const [padding, setPadding] = useState(20)
    const circleRef = useRef();
    const hrRef = useRef();
    const contentRef = useRef();
    const columnData = ["outline", "history", "partners"];
    const columnKorData = ["회사개요", "연혁", "파트너사"];
    const columnEnData = ["Overview", "History", "Partners"];
    const [getData, setData] = useState();
    const [screenWidth, setScreenWidth] = useState(document.body.clientWidth);
    const [autoHeight, setAutoHeight] = useState([0, 0, 0]);

    const AboutData = {
        history: [
            {
                year: 2024,
                data: [
                    {
                        id: 11,
                        month: 5,
                        content: "대표이사 '이정기'취임, '한주에이알티 주식회사'사명변경",
                        e_content: "CEO Lee Jeong-ki takes office, changes name of HANJOO A.R.T. Co., Ltd"
                    }
                ]
            },
            {
                year: 2023,
                data: [
                    {
                        id: 1,
                        month: 3,
                        content: "‘엔터파트너즈’ 사명 변경",
                        e_content: "Changed company name to ‘Enterpartners’"
                    }
                ]
            },
            {
                year: 2014,
                data: [
                    {
                        id: 5,
                        month: 12,
                        content: "기술혁신형 중소기업 INNOBIZ 인증 (중소기업청장)",
                        e_content: "INNOBIZ certification for technologically innovative SMEs (Small and Medium Business Administration)"
                    }
                ]
            },
            {
                year: 2012,
                data: [
                    {
                        id: 6,
                        month: 8,
                        content: "제 4차 중소기업을 빛낸 얼굴들 수상 (중소기업청, 중소기업중앙회)",
                        e_content: "Awarded the 4th Faces of Small and Medium Business"
                    }
                ]
            },
            {
                year: 2011,
                data: [
                    {
                        id: 7,
                        month: 3,
                        content: "이달의 자랑스런 중소기업인상 수상 (중소기업청, 중소기업중앙회)",
                        e_content: "Proud Small Business Award of the Month"
                    },
                    {
                        id: 8,
                        month: 1,
                        content: "“일하기 좋은 기업” 잡 월드 베스트 600기업 선정",
                        e_content: "“Great place to work” Selected as one of the world’s best 600 companies"
                    }
                ]
            },
            {
                year: 1987,
                data: [
                    {
                        id: 9,
                        month: 3,
                        content: "조세의 날 “재무부장관상” 수상",
                        e_content: "Received the “Minister of Finance Award” on Tax Day"
                    }
                ]
            },
            {
                year: 1978,
                data: [
                    {
                        id: 10,
                        month: 2,
                        content: "주식회사 설립",
                        e_content: "Establishment of a joint stock company"
                    }
                ]
            },
        ],
        partners: [
            {
                id: 1,
                img: partner_logo1
            },
            {
                id: 2,
                img: partner_logo2
            }
        ]
    };


    function changeColumn(column) {
        searchParams.set("column", column)
        setSearchParams(searchParams)
    }

    function getPadding(dataCount) {
        var _padding = 20;

        if(dataCount === 0) {
            if(document.body.clientWidth > 1317) {
                _padding = ((document.body.clientWidth - 1300) / 2);
            }
            return _padding;
        }

        for(var i=1; i<=dataCount; i++) {
            if(document.body.clientWidth > 1317) {
                _padding = ((document.body.clientWidth - 1300) / 2);
            } else {
                if((152 * i + 16 * (i - 1)) > document.body.clientWidth) {
                    _padding = (document.body.clientWidth - (152 * (i - 1) + 16 * (i - 2))) / 2;
                    break
                }
            }
        }
        return _padding;
    }

    async function apiCall() {
        try {
            const response = await axios.get(process.env.REACT_APP_SERVER +'/api/data/about');
            setData(response.data.data)
        } catch(err) {
            console.log("ERROR: ", err);
        }
    }

    useEffect(() => {
        setPadding(getPadding(getData === undefined ? 0 : getData.partners.length));
        window.addEventListener('resize', () => {
            setPadding(getPadding(getData === undefined ? 0 : getData.partners.length));
            setScreenWidth(document.body.clientWidth);
        });
        return () => {
            window.removeEventListener('resize', () => {
                setPadding(getPadding(getData === undefined ? 0 : getData.partners.length));
                setScreenWidth(document.body.clientWidth);
            });
        }
    }, [getData]);

    useEffect(() => {
        apiCall()
    }, [])

    useEffect(() => {
        setColumn(searchParams.get("column"));
        t()
        switch (columnData.indexOf(searchParams.get("column"))) {
            case 0:
                if(document.body.clientWidth > 1317) {
                    circleRef.current.style.left = "0";
                } else {
                    circleRef.current.style.left = "20px";
                }
                contentRef.current.style.transform = "translateX(0)";
                contentRef.current.parentNode.style.height = autoHeight[0] + "px";
                return
            case 1:
                if(document.body.clientWidth > 1317) {
                    circleRef.current.style.left = hrRef.current.children[1].getBoundingClientRect().x - hrRef.current.getBoundingClientRect().x + hrRef.current.children[1].clientWidth / 2 - circleRef.current.clientWidth / 2 + "px"
                } else {
                    circleRef.current.style.left = hrRef.current.children[1].getBoundingClientRect().x + hrRef.current.children[1].clientWidth / 2 - circleRef.current.clientWidth / 2 + "px"
                }
                contentRef.current.style.transform = "translateX(-100%)";
                contentRef.current.parentNode.style.height = autoHeight[1] + "px";
                return
            case 2:
                if(document.body.clientWidth > 1317) {
                    circleRef.current.style.left = hrRef.current.clientWidth - 16 + "px";
                } else {
                    circleRef.current.style.left = hrRef.current.clientWidth + 12 + "px";
                }
                contentRef.current.style.transform = "translateX(-200%)";
                contentRef.current.parentNode.style.height = autoHeight[2] + "px";
                return
            default:
                setColumn("outline");
                if(document.body.clientWidth > 1317) {
                    circleRef.current.style.left = "0";
                } else {
                    circleRef.current.style.left = "20px";
                }
                contentRef.current.style.transform = "translateX(0)";
                contentRef.current.parentNode.style.height = autoHeight[0] + "px";
                return
        }
    }, [searchParams, screenWidth, autoHeight])

    function t(e) {
        if(JSON.stringify(autoHeight) !== JSON.stringify([
            contentRef.current.childNodes[0].clientHeight,
            contentRef.current.childNodes[1].clientHeight,
            contentRef.current.childNodes[2].clientHeight
        ])) {
            setAutoHeight([
                contentRef.current.childNodes[0].clientHeight,
                contentRef.current.childNodes[1].clientHeight,
                contentRef.current.childNodes[2].clientHeight
            ]);
        }
    }

    return(
        <div className={style.wrap} onLoad={t}>
            <SEOMetaTag title="회사소개" description="한주에이알티 주식회사 회사소개 페이지" url="https://hanjooart.com/about"></SEOMetaTag>
            <div className={style.topContainer}>
                <span>{isEn ? "About" : "회사소개"}</span>
                <div ref={hrRef} className={style.columnContainer}>
                    {
                        columnData.map((data, idx) => (
                            <span key={idx} onClick={() => changeColumn(data)} className={data === column ? `${style.columnContainerItem} ${style.selected}` : style.columnContainerItem}>{isEn ? columnEnData[idx] : columnKorData[idx]}</span>
                        ))
                    }
                </div>
                <div ref={circleRef} className={style.hrCircle}></div>
            </div>
            <div className={style.bottomContainer}>
                <div ref={contentRef} className={style.bottomContainerWrap}>
                    <div className={style.contentContainer}>
                        <div className={style.outlineImgContainer}>
                            <img className={style.mainLogo} src={`${isEn ? mainLogoE : mainLogo}`} alt="main-logo"></img>
                            <div className={style.outlineContainerIntro}>
                                <span>{isEn ? "HANJOO A.R.T. presents diverse media content, encompassing production to performances." : "한주에이알티 주식회사는 제작부터 공연까지 다양한 사업 영역을 기반으로 종합 미디어 콘텐츠를 선보입니다."}</span>
                                <span>{isEn ? "By creating a media value chain, we drive growth in South Korea’s media industry and expand our ecosystem." : "미디어 밸류 체인의 구축으로 자체 생태계를 확장하고 대한민국 미디어 산업의 성장을 선도합니다."}</span>
                            </div>
                            <img className={style.outlineContentTop} src={isEn ? outlineImgTopEn : outlineImgTop} alt="outline-img-top"></img>
                            <div className={style.outlineContentBottom}>
                                <img src={isEn ? outlineImgBottomEn : outlineImgBottom} alt="outline-img-bottom"></img>
                            </div>
                            <hr></hr>
                            <div className={style.outlineContainerIntro}>
                                <span>{isEn ? "HANJOO A.R.T.’ dream becomes reality soon, inspiring a fresh breeze of hope.": "한주에이알티 주식회사의 꿈은 곧 현실이 됩니다.\n우리의 꿈과 희망은 새로운 바람을 일으킵니다."}</span>
                                <span>{isEn ? "We promise to empower the globalization of K-content through innovative new content." : "새로운 콘텐츠를 통해서 K-content의 글로벌화에 힘을 실어 드릴 것을 약속드립니다."}</span>
                            </div>
                        </div>
                        <div className={style.circleContainer}>
                            <div className={style.outlineCircle}>
                                <span>{isEn ? "Introduction" : "회사소개"}</span>
                                <span>COMPANY</span>
                                <span>{isEn ? "We are a company that constantly urges ourselves every day for a vision for the future and rewards our customers." : "한주에이알티 주식회사는 항상 미래를 향한 비전을 위하여 날마다 우리 스스로를 끊임없이 재촉하며, 고객을 보답하는 기업입니다."}</span>
                            </div>
                            <div className={style.outlineCircle}>
                                <span>{isEn ? "Areas" : "사업분야"}</span>
                                <span>BUSINESS</span>
                                <span>{isEn ? "We promise to be a good partner for business models with know-how accumulated for 37 years since the establishment on February 24, 1978." : "한주에이알티 주식회사는 1978년 2월 24일 창사 이후 37년간 축적된 노하우로 비니지스 모델에 좋은 파트너가 될 것을 약속드립니다."}</span>
                                <div className={style.outlineHr}>
                                    <hr></hr>
                                    <div className={style.outlineHrCircle}></div>
                                    <div className={style.outlineHrCircle}></div>
                                </div>
                                <div className={style.outlineHr}>
                                    <hr></hr>
                                    <div className={style.outlineHrCircle}></div>
                                    <div className={style.outlineHrCircle}></div>
                                </div>
                            </div>
                            <div className={style.outlineCircle}>
                                <span>{isEn ? "Creations" : "창작물"}</span>
                                <span>PRODUCT</span>
                                <span>{isEn ? "We pursue continuous high growth through multifaceted growth strategies. We will become a company that repays with high-quality creations." : "한주에이알티 주식회사는 다각도의 성장전략을 통한 지속적인 고성장을 추구합니다. 완성도 높은 창작물로 보답하는 기업이 되겠습니다."}</span>
                            </div>
                        </div>
                    </div>
                    <div className={style.contentContainer}>
                        {
                            AboutData.history.map((data, idx) => (
                                <div key={idx} className={style.historyItem}>
                                    <span className={style.historyYear}>{data.year}</span>
                                    {
                                        data.data.map((indata) => (
                                            <div key={indata.id} className={style.itemContentContainer}>
                                                <div className={style.itemContent}>
                                                    <div className={style.historyCircle}>
                                                        <div className={style.monthCircle}>
                                                            <span>{indata.month}</span>
                                                        </div>
                                                        <hr className={style.monthHr}></hr>
                                                        <div className={style.monthCircle2}></div>
                                                    </div>
                                                    <span className={style.monthContent}>{isEn ? indata.e_content : indata.content}</span>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            ))
                        }
                    </div>
                    <div className={style.contentContainer} style={{padding: "0 " + padding + "px"}}>
                        {
                            AboutData.partners.map((data) => (
                                <div key={data.id} className={style.partnerItem}>
                                    <img src={data.img} alt="partners-img"></img>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
            {
                admin &&
                column === 'history' &&
                <div className={style.adminContainer}>
                <button className={style.adminBtn} onClick={() => {setShowAdmin(true); setAdminField('about')}}>연혁 관리</button>
                </div>
            }
            <Portal>
                {
                    showAdmin &&
                    <AdminModal setRender={ setRender } admin={admin} setAdmin={setAdmin} showAdmin={showAdmin} setShowAdmin={setShowAdmin} adminField={adminField} setAdminField={setAdminField}></AdminModal>
                }
            </Portal>
        </div>
    )
}