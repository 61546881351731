import style from "../css/Pr.module.css";
import {NavLink, useNavigate, useSearchParams, Link} from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import pdfFile from "../file/policy.pdf"
import Pagination from "../Components/Pagination";
import SEOMetaTag from "../Components/SEOMetaTag";
import Portal from '../Components/Portal';
import AdminModal from '../AdminComponents/AdminModal';

export default function Pr({isEn, setCurrentPageNews, currentPageNews, currentPageNotice, setCurrentPageNotice, currentPageMedia, setCurrentPageMedia, currentPageDisc, setCurrentPageDisc, admin, setAdmin, showAdmin, setShowAdmin, adminField, setAdminField, render, setRender}) {
    const [searchParams, setSearchParams] = useSearchParams();
    const [column, setColumn] = useState(searchParams.get("column"));
    const circleRef = useRef();
    const contentRef = useRef();
    const hrRef = useRef();
    const columnData = ["notice", "advertisement", "press", "disclosure", "policy"];
    const columnKorData = ["공지사항", "언론 및 광고", "보도자료", "공시자료", "회사규정"];
    const columnEnData = ["Notice", "Advertisement", "Press", "Disclosure", "Policy"];
    const [getData, setData] = useState();
    const [screenWidth, setScreenWidth] = useState(document.body.clientWidth);
    const [autoHeight, setAutoHeight] = useState([0, 0, 0, 0]);
    const navigate = useNavigate();

    const [postsPerPage, setPostsPerPage] = useState(document.body.clientWidth > 1317 ? 10 : 5);

    const indexOfLastNews = currentPageNews * postsPerPage;
    const indexOfFirstNews = indexOfLastNews - postsPerPage;
    const currentPostsNews = function(posts) {
        let currentPosts1 = 0;
        currentPosts1 = posts.slice(indexOfFirstNews, indexOfLastNews);
        return currentPosts1;
    };

    const indexOfLastNotice = currentPageNotice * postsPerPage;
    const indexOfFirstNotice = indexOfLastNotice - postsPerPage;
    const currentPostsNotice = function(posts) {
        let currentPosts2 = 0;
        currentPosts2 = posts.slice(indexOfFirstNotice, indexOfLastNotice);
        return currentPosts2;
    };

    const indexOfLastMedia = currentPageMedia * postsPerPage;
    const indexOfFirstMedia = indexOfLastMedia - postsPerPage;
    const currentPostsMedia = function(posts) {
        let currentPosts3 = 0;
        currentPosts3 = posts.slice(indexOfFirstMedia, indexOfLastMedia);
        return currentPosts3;
    };

    const indexOfLastDisc = currentPageDisc * postsPerPage;
    const indexOfFirstDisc = indexOfLastDisc - postsPerPage;
    const currentPostsDisc = function(posts) {
        let currentPosts4 = 0;
        currentPosts4 = posts.slice(indexOfFirstDisc, indexOfLastDisc);
        return currentPosts4;
    };

    function changeColumn(column) {
        searchParams.set("column", column)
        setSearchParams(searchParams)
    }

    async function apiCall() {
        try {
            const response = await axios.get(process.env.REACT_APP_SERVER +'/api/data/pr');
            setData(response.data.data)
        } catch(err) {
            console.log("ERROR: ", err);
        }
    }

    useEffect(() => {
        window.addEventListener('resize', () => {
            setScreenWidth(document.body.clientWidth);
        });
        return () => {
            window.removeEventListener('resize', () => {
                setScreenWidth(document.body.clientWidth);
            });
        }
    }, [])

    useEffect(() => {
        setColumn(searchParams.get("column"));
        t()
        switch (columnData.indexOf(searchParams.get("column"))) {
            case 0:
                if(document.body.clientWidth > 1317) {
                    circleRef.current.style.left = "0";
                } else {
                    circleRef.current.style.left = "20px";
                }
                contentRef.current.style.transform = "translateX(0)";
                contentRef.current.parentNode.style.height = autoHeight[0] + "px";
                return
            case 1:
                if(document.body.clientWidth > 1317) {
                    circleRef.current.style.left = hrRef.current.children[1].getBoundingClientRect().x - hrRef.current.getBoundingClientRect().x + hrRef.current.children[1].clientWidth / 2 - circleRef.current.clientWidth / 2 + "px"
                } else {
                    circleRef.current.style.left = hrRef.current.children[1].getBoundingClientRect().x + hrRef.current.children[1].clientWidth / 2 - circleRef.current.clientWidth / 2 + "px"
                }
                contentRef.current.style.transform = "translateX(-100%)";
                contentRef.current.parentNode.style.height = autoHeight[1] + "px";
                return
            case 2:
                if(document.body.clientWidth > 1317) {
                    circleRef.current.style.left = hrRef.current.children[2].getBoundingClientRect().x - hrRef.current.getBoundingClientRect().x + hrRef.current.children[2].clientWidth / 2 - circleRef.current.clientWidth / 2 + "px"
                } else {
                    circleRef.current.style.left = hrRef.current.children[2].getBoundingClientRect().x + hrRef.current.children[2].clientWidth / 2 - circleRef.current.clientWidth / 2 + "px"
                }
                contentRef.current.style.transform = "translateX(-200%)";
                contentRef.current.parentNode.style.height = autoHeight[2] + "px";
                return
            case 3:
                if(document.body.clientWidth > 1317) {
                    circleRef.current.style.left = hrRef.current.children[3].getBoundingClientRect().x - hrRef.current.getBoundingClientRect().x + hrRef.current.children[2].clientWidth / 2 - circleRef.current.clientWidth / 2 + "px"
                } else {
                    circleRef.current.style.left = hrRef.current.children[3].getBoundingClientRect().x + hrRef.current.children[3].clientWidth / 2 - circleRef.current.clientWidth / 2 + "px"
                }
                contentRef.current.style.transform = "translateX(-300%)";
                contentRef.current.parentNode.style.height = autoHeight[3] + "px";
                return
            case 4:
                if(document.body.clientWidth > 1317) {
                    circleRef.current.style.left = hrRef.current.clientWidth - 16 + "px";
                } else {
                    circleRef.current.style.left = hrRef.current.clientWidth + 12 + "px";
                }
                contentRef.current.style.transform = "translateX(-400%)";
                contentRef.current.parentNode.style.height = autoHeight[4] + "px";
                return
            default:
                setColumn("notice");
                if(document.body.clientWidth > 1317) {
                    circleRef.current.style.left = "0";
                } else {
                    circleRef.current.style.left = "20px";
                }
                contentRef.current.style.transform = "translateX(0)";
                contentRef.current.parentNode.style.height = autoHeight[0] + "px";
                return
        }
    }, [searchParams, screenWidth, autoHeight])

    useEffect(() => {
        apiCall()
    }, [])

    function t(e) {
        if(JSON.stringify(autoHeight) !== JSON.stringify([
            contentRef.current.childNodes[0].clientHeight,
            contentRef.current.childNodes[1].clientHeight,
            contentRef.current.childNodes[2].clientHeight,
            contentRef.current.childNodes[3].clientHeight,
            contentRef.current.childNodes[4].clientHeight
        ])) {
            setAutoHeight([
                contentRef.current.childNodes[0].clientHeight,
                contentRef.current.childNodes[1].clientHeight,
                contentRef.current.childNodes[2].clientHeight,
                contentRef.current.childNodes[3].clientHeight,
                contentRef.current.childNodes[4].clientHeight
            ]);
        }
    }

    return(
        <div className={style.wrap} onLoad={t}>
            <SEOMetaTag title="PR" description="한주에이알티 쥐식회사 PR 페이지" url="https://hanjooart.com/pr"></SEOMetaTag>
            <div className={style.topContainer}>
                <span>PR</span>
                <div ref={hrRef} className={style.columnContainer}>
                    {
                        columnData.map((data, idx) => (
                            <span key={idx} onClick={() => changeColumn(data)} className={data === column ? `${style.columnContainerItem} ${style.selected}` : style.columnContainerItem}>{isEn ? columnEnData[idx] : columnKorData[idx]}</span>
                        ))
                    }
                </div>
                <div ref={circleRef} className={style.hrCircle}></div>
            </div>
            <div className={style.bottomContainer}>
                <div ref={contentRef} className={style.bottomContainerWrap}>
                    <div className={style.contentContainer}>
                        <div>
                            {
                                getData !== undefined &&
                                currentPostsNotice(getData.notice).map((data) => (
                                    <NavLink key={data.id}
                                             to={"/pr/detail?column=notice&pagenum=" + currentPageNotice + "&postId=" + data.id}
                                             className={style.noticeItem}>
                                        <div className={style.noticeContent}>
                                            <span>{isEn ? data.e_date : data.date}</span>
                                            <span>{isEn ? data.e_theme : data.theme}</span>
                                            <span>{isEn ? data.e_content : data.content}</span>
                                        </div>
                                    </NavLink>
                                ))
                            }
                        </div>
                        <div className={style.pageNationContainer}>
                            {
                                getData !== undefined &&
                                <Pagination postsPerPage={postsPerPage} totalPosts={getData.notice.length}
                                            paginate={setCurrentPageNotice}
                                            currentPage={currentPageNotice}></Pagination>
                            }
                        </div>
                    </div>

                    <div className={style.contentContainer}>
                        <div>
                            {
                                getData !== undefined &&
                                currentPostsMedia(getData.advertisement).map((data) => (
                                    <NavLink key={data.id}
                                             to={"/pr/detail?column=advertisement&pageNum=" + currentPageMedia + "&postId=" + data.id}
                                             className={style.newsItem}>
                                        {
                                            data.img &&
                                            <img src={data.img} alt="advertisementImg"></img>
                                        }
                                        <div className={style.newsContent}>
                                            <span>{isEn ? data.e_date : data.date}</span>
                                            <span>{isEn ? data.e_theme : data.theme}</span>
                                            <span>{isEn ? data.e_content : data.content}</span>
                                        </div>
                                    </NavLink>
                                ))
                            }
                        </div>
                        <div className={style.pageNationContainer}>
                            {
                                getData !== undefined &&
                                <Pagination postsPerPage={postsPerPage} totalPosts={getData.advertisement.length}
                                            paginate={setCurrentPageMedia} currentPage={currentPageMedia}></Pagination>
                            }
                        </div>
                    </div>
                    <div className={style.contentContainer}>
                        <div>
                            {
                                getData !== undefined &&
                                currentPostsNews(getData.press).map((data) => (
                                    <NavLink key={data.id}
                                             to={"/pr/detail?column=press&pageNum=" + currentPageNews + "&postId=" + data.id}
                                             className={style.newsItem}>
                                        {
                                            data.img &&
                                            <img src={data.img} alt="pressImg"></img>
                                        }
                                        <div className={style.newsContent}>
                                            <span>{isEn ? data.e_date : data.date}</span>
                                            <span>{isEn ? data.e_theme : data.theme}</span>
                                            <span>{isEn ? data.e_content : data.content}</span>
                                        </div>
                                    </NavLink>
                                ))
                            }
                        </div>
                        <div className={style.pageNationContainer}>
                            {
                                getData !== undefined &&
                                <Pagination postsPerPage={postsPerPage} totalPosts={getData.press.length}
                                            paginate={setCurrentPageNews} currentPage={currentPageNews}></Pagination>
                            }
                        </div>
                    </div>
                    <div className={style.contentContainer}>
                        <div>
                            {
                                getData !== undefined &&
                                currentPostsDisc(getData.disclosure).map((data) => (
                                    <NavLink key={data.id} to={data.link} target="_black" className={style.noticeItem}>
                                        <div className={style.noticeContent}>
                                            <span>{isEn ? data.e_date : data.date}</span>
                                            <div className={style.discDiv}>
                                                <span>{isEn ? data.e_theme : data.theme}</span>
                                                <span>{isEn ? data.e_source : data.source}</span>
                                            </div>
                                        </div>
                                    </NavLink>
                                ))
                            }
                        </div>
                        <div className={style.pageNationContainer}>
                            {
                                getData !== undefined &&
                                <Pagination postsPerPage={postsPerPage} totalPosts={getData.disclosure.length}
                                            paginate={setCurrentPageDisc} currentPage={currentPageDisc}></Pagination>
                            }
                        </div>
                    </div>
                    <div className={style.contentContainer}>
                        <Link to={pdfFile} target="_blank" className={style.noticeItem}>
                            <div className={style.discDiv}>
                                <span>{isEn ? "policy" : "내부정보관리규정"}</span>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
            {
                admin &&
                <div className={style.adminContainer}>
                    <button className={style.adminBtn} onClick={() => {
                        setShowAdmin(true);
                        setAdminField('pr')
                    }}>{columnKorData[columnData.indexOf(column)]} 관리
                    </button>
                </div>
            }
            <Portal>
            {
                    showAdmin &&
                    <AdminModal render={render} setRender={setRender} admin={admin} setAdmin={setAdmin}
                                showAdmin={showAdmin} setShowAdmin={setShowAdmin} adminField={adminField}
                                setAdminField={setAdminField}></AdminModal>
                }
            </Portal>
        </div>
    )
}